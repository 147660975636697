<template>
  <main class="member-shop-giveaway">
    <PageTitle title="商城贈品活動" btn="新增" @btnClick="onCreateGiveaway" />
    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="輸入活動名稱"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-select
        v-model="search.status"
        clearable
        placeholder="選擇活動狀態"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="status in giveawayStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </FiltersContainer>
    <MemberShopGiveawayTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, reactive, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import MemberShopGiveawayTable from './components/MemberShopGiveawayTable.vue'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'
import { giveawayStatusConfig } from '@/config/memberShop'
import { GetMemberShopGiveaway, GetMemberShopGiveawayCount } from '@/api/memberShop'

export default defineComponent({
  name: 'MemberShopOrderGiveaway',
  components: { PageTitle, FiltersContainer, MemberShopGiveawayTable },
  setup (props) {
    const router = useRouter()
    const onCreateGiveaway = () => {
      router.push({ name: 'MemberShopGiveawayEdit' })
    }
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex, shopId } = useTable()
    const search = reactive({
      name: null,
      status: null,
    })

    const getMemberShopGiveaway = async (payload) => {
      const [res, err] = await GetMemberShopGiveaway(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getMemberShopGiveawayCount = async (payload) => {
      const [res, err] = await GetMemberShopGiveawayCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        status: search.status || undefined,
      }
      await Promise.allSettled([
        getMemberShopGiveaway(payload),
        getMemberShopGiveawayCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })

    return {
      search,
      tableData,
      tableDataCount,
      tableOptions,
      onCreateGiveaway,
      refresh,
      giveawayStatusConfig,
      loading,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
